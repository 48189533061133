import React from "react";
import './Navigation.scss'
import Section from './Section/Section'

const Sections = [
  {
    title: "Book Video Solutions",
    links: [
      {
        title: "Statics",
        url: "statics"
      },{
        title: "Dynamics",
        url: "dynamics"
      },{
        title: "Calculus",
        url: "calculus"
      },{
        title: "Microelectronic Circuits",
        url: "microelectronic_circuits"
      }
    ]
  },{
    title: "How to support this channel",
    links: [
      {
        title: "Donate",
        url: "https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=SST3P5DBYDKG4&item_name=FinalAnswer&currency_code=USD"
      },{
        title: "Patreon",
        url: "https://www.patreon.com/daxterbels"
      },{
        title: "Subscribe",
        url: "https://www.youtube.com/c/FinalAnswer"
      },{
        title: "Buy a calculator",
        url: "buy_calculator"
      },{
        title: "Buy a book",
        url: "buy_book"
      },{
        title: "Buy a shirt",
        url: "https://teespring.com/stores/final-answer-store"
      }
    ]
  }
]

const Navigation = (props) => {
  const navClasses = ["navigation"]

  if(!props.show)
    navClasses.push("hide-navigation")

  return (
    <div className={navClasses.join(' ')}>
      {Sections.map(section => {
        return <Section key={section.title} data={section} />
      })}
    </div>
  )
}

export default Navigation