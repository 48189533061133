import React, {useEffect, useState} from "react";
import {getBook} from "../services/ProblemsAPI";
import {NavLink, withRouter} from "react-router-dom";

function compare(a, b){
  const titleA = a.title
  const titleB = b.title

  let comparison = 0;
  if (titleA > titleB) {
    comparison = 1;
  } else if (titleA < titleB) {
    comparison = -1;
  }
  return comparison;
}

const Book = (props) => {
  const [book, setBook] = useState({})
  const [chapters, setChapters] = useState([])
  const [selectedChapter, setSelectedChapter] = useState({})

  let renderLectures = null;
  let renderProblems = null;

  useEffect(() => {
    setBook({})
    getBook(props.match.params.bookId)
      .then(res => {
        setBook(res.data.data)
        setChapters(res.data.data.chapters)
        if(props.match.params.chapterId){
          const findChapter = res.data.data.chapters.find((element) => element.id === parseInt(props.match.params.chapterId))

          setSelectedChapter(findChapter)
        }
      }).catch(err => {
        console.log(err)
      })
  }, [props.match.params.bookId, props.match.params.chapterId])

  let renderChapters = null
  let renderChapter = null

  if(!props.match.params.chapterId){
    renderChapters = chapters.map((chapter) => {
      return (
        <div key={chapter.id}>
          <NavLink to={"/book/" + book.id + "/" + chapter.id}>
            {chapter.title}
          </NavLink>
        </div>
      )
    })
  }else{
    if(selectedChapter.problems){
      selectedChapter.problems.sort(compare)

      renderProblems = selectedChapter.problems.map((problem) => {
        return (
          <div key={problem.id}>
            <a href={problem.video_url} target="_blank" rel="noopener noreferrer">
              {problem.title}
            </a>
          </div>
        )
      });
    }

    if(selectedChapter.lectures){
      selectedChapter.lectures.sort(compare)

      renderLectures = selectedChapter.lectures.map((lecture) => {
        return (
          <div key={lecture.id}>
            <a href={lecture.video_url} target="_blank" rel="noopener noreferrer">
              {lecture.title}
            </a>
          </div>
        )
      });
    }

    renderChapter = (
      <div>
        <h2>
          {selectedChapter.title}
        </h2>
        <div>
          <h3>
            Lectures
          </h3>
          {renderLectures}
          <h3>
            Problems
          </h3>
          {renderProblems}
        </div>
      </div>
    )
  }

  return (
    <div>
      <h1>
        {book.title}
      </h1>
      {renderChapters}
      {renderChapter}
    </div>
  )
}

export default withRouter(Book)