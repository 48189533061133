import React, {useEffect, useState} from "react";
import {NavLink, withRouter} from "react-router-dom";
import {getBooksByCourse} from "../services/ProblemsAPI";
import urlToTitle from "../services/Helper";
import './Class.scss'

const Class = (props) => {
  const [books, setBooks] = useState([])

  useEffect(() => {
    setBooks([])
    getBooksByCourse(props.location.pathname)
      .then(res => {
        setBooks(res.data.data)
      }).catch(err => {
        console.log(err)
      })
  }, [props.location.pathname])

  return (
    <div>
      <h1 className="class-title">
        {urlToTitle(props.location.pathname)}
      </h1>
      <div>
        <h2>
          Books
        </h2>
        {books.map((book) => {
          return (
            <div key={book.id}>
              <NavLink to={"/book/" + book.id}>
                {book.title}
              </NavLink>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default withRouter(Class)