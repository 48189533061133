import React from "react";
import './Section.scss'
import {NavLink} from "react-router-dom";

const Section = (props) => {
  return (
    <div className="nav-section">
      <div className="nav-title">
        {props.data.title}
      </div>
      {props.data.links.map(link => {
        if(link.url.includes('https://'))
          return (
            <div key={link.title} className="nav-link">
              <a className="navigation-link" href={link.url} rel="noopener noreferrer" target="_blank">{link.title}</a>
            </div>
          )
        else
          return (
            <div key={link.title} className="nav-link">
              <NavLink className="navigation-link" to={"/" + link.url}>{link.title}</NavLink>
            </div>
          )
      })}
    </div>
  )
}

export default Section