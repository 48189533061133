import React from "react";
import {NavLink} from "react-router-dom";

const Home = () => {
  return (
    <div>
      <h2>
        About
      </h2>
      <p>
        Hi! My name is Dax. I graduated Florida International University and got my Electrical Engineering degree. I love physics and I am that nerdy guy that loves doing physics. A lot of people, including myself, get stuck in homework problems, so I decided to solve homework problems for fun and share them with the rest of you. Hopefully these will help you graduate and most importantly, learn. Click on the links below for book solutions:
      </p>
      <div>
        <h3>
          Classes:
        </h3>
        <ul>
          <li>
            <NavLink className="nav-link" to={"/statics"}>
              Statics
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={"/dynamics"}>
              Dynamics
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={"/microelectronic_circuits"}>
              Microelectronic Circuits
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={"/calculus"}>
              Calculus
            </NavLink>
          </li>
        </ul>
      </div>
      <div>
        <h3>
          Books:
        </h3>
        <ul>
          <li>
            <NavLink className="nav-link" to={"/book/1"}>
              Engineering Mechanics: Statics by Hibbeler 14th Edition
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={"/book/2"}>
              Engineering Mechanics: Dynamics by Hibbeler 14th Edition
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={"/book/3"}>
              Thomas’ Calculus Early Transcendentals 11th edition
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={"/book/4"}>
              Microelectronic Circuits Sedra/Smith 7th edition Solution Videos
            </NavLink>
          </li>
        </ul>
      </div>
      <div>
        <h3>
          Support Final Answer:
        </h3>
        <ul>
          <li>
            <a className="nav-link" href="https://www.paypal.com/donate/?cmd=_donations&business=SST3P5DBYDKG4&item_name=FinalAnswer&currency_code=USD&Z3JncnB0=" rel="noopener noreferrer" target="_blank">
              Donate
            </a>
          </li>
          <li>
            <a className="nav-link" href="https://www.patreon.com/daxterbels" rel="noopener noreferrer" target="_blank">
              Patreon
            </a>
          </li>
          <li>
            <a className="nav-link" href="https://www.youtube.com/c/FinalAnswer" rel="noopener noreferrer" target="_blank">
              Subscribe
            </a>
          </li>
          <li>
            <NavLink className="nav-link" to={"/buy_calculator"}>
              Buy a calculator
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to={"/buy_book"}>
              Buy a book
            </NavLink>
          </li>
          <li>
            <a className="nav-link" href="https://www.youtube.com/c/FinalAnswer/store" rel="noopener noreferrer" target="_blank">
              Buy a shirt
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Home