import axios from 'axios'

const urlToClass = {
  statics: 1,
  dynamics: 2,
  calculus: 3,
  microelectronic_circuits: 4
}

const responses = {}

const getBooksByCourse = (url = '') => {
  const classId = urlToClass[url.replace('/', '')]
  return apiCall('books?university_class_id=' + classId)
}

const getBook = (bookId) => {
  return apiCall('books/' + bookId)
}

const tryRequire = (path) => {
  try {
    return require(`../api/responses/${path}.json`);
  } catch (err) {
    return null;
  }
};

const apiCall = (url) => {
  if(!responses[process.env.REACT_APP_PROBLEMS_API + url]){
    let tryJson = tryRequire(encodeURIComponent(url))
    if(tryJson){
      responses[process.env.REACT_APP_PROBLEMS_API + url] = new Promise((resolve) => {
        const response = {
          data: tryJson
        }
        return resolve(response)
      })
    }else{
      responses[process.env.REACT_APP_PROBLEMS_API + url] = axios.get(process.env.REACT_APP_PROBLEMS_API + url)
    }
  }

  return responses[process.env.REACT_APP_PROBLEMS_API + url]
}

export { getBooksByCourse, getBook, apiCall as default }
