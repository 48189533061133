import React from "react";
import './BuyCalculator.scss'

const BuyBook = () => {
  return (
    <div>
      <h1>
        Buy your books through these links to help FinalAnswer!
      </h1>
      <div className="calculators">
        <div className="each-calculator">
          <h2>
            Statics and Dynamics Hibbeler 14th Edition
          </h2>
          <div>
            <div>
              <a href="https://amzn.to/3mmOux8" target="_blank" rel="noopener noreferrer">
                <img src="https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=0133915425&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=jaghelp-20" alt="Statics and Dynamics Hibbeler 14th"/>
              </a>
            </div>
            <div>
              <h3>
                <a href="https://amzn.to/3mmOux8" target="_blank" rel="noopener noreferrer">
                  Click here to buy
                </a>
              </h3>
            </div>
          </div>
        </div>
        <div className="each-calculator">
          <h2>
            Microelectronic Circuits by Sedra and Smith
          </h2>
          <div>
            <div>
              <a href="https://amzn.to/31E63kE" target="_blank" rel="noopener noreferrer">
                <img src="https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=0195323033&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=jaghelp-20" alt="Microelectronic Circuits by Sedra Smith"/>
              </a>
            </div>
            <div>
              <h3>
                <a href="https://amzn.to/31E63kE" target="_blank" rel="noopener noreferrer">
                  Click here to buy
                </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="calculators">
        <div className="each-calculator">
          <h2>
            Calculus Early Trascendentals
          </h2>
          <div>
            <div>
              <a href="https://amzn.to/2FYvwxv" target="_blank" rel="noopener noreferrer">
                <img src="https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=1285741552&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=jaghelp-20" alt="Calculus Early Trascendentals"/>
              </a>
            </div>
            <div>
              <h3>
                <a href="https://amzn.to/2FYvwxv" target="_blank" rel="noopener noreferrer">
                  Click here to buy
                </a>
              </h3>
            </div>
          </div>
        </div>
        <div className="each-calculator">
          <h2>
            Power Systems Analysis and Design
          </h2>
          <div>
            <div>
              <a href="https://amzn.to/37JjkMq" target="_blank" rel="noopener noreferrer">
                <img src="https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=1305632133&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=jaghelp-20" alt="Power System Analysis and Design by Duncan Glover"/>
              </a>
            </div>
            <div>
              <h3>
                <a href="https://amzn.to/37JjkMq" target="_blank" rel="noopener noreferrer">
                  Click here to buy
                </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyBook