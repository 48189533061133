import React, {useState} from "react";
import Navigation from "../Navigation/Navigation";
import './Layout.scss'
import Class from "../../components/Class";
import Book from "../../components/Book";
import Home from "../../components/Home";
import BuyBook from "../../components/BuyBook";
import BuyCalculator from "../../components/BuyCalculator";
import {Switch, Route, NavLink} from "react-router-dom";
import menu from '../../icons/menu.svg'

const Layout = () => {
  let navInitialState = true
  if(window.innerWidth < 768)
    navInitialState = false

  const [showNav, setShowNav] = useState(navInitialState)

  const toggleNav = (event) => {
    setShowNav(!showNav)
  }

  React.useEffect(() => {
    function handleResize() {
      if(window.innerWidth < 768)
        setShowNav(false)
      else
        setShowNav(true)
    }

    window.addEventListener('resize', handleResize)
  })

  return (
    <div>
      <div className="top-bar">
        <div className="title">
          <img onClick={toggleNav} src={menu} alt="Menu"/>
          <NavLink className="navigation-link" to={"/"}>
            finalanswer.com
          </NavLink>
        </div>
        <div>
          <a target="_blank" rel="noopener noreferrer" className="donate-button" href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=SST3P5DBYDKG4&item_name=FinalAnswer&currency_code=USD">
            Donate
          </a>
        </div>
      </div>
      <div className="layout">
        <Navigation show={showNav} />
        <div className="main-section">
          <Switch>
            <Route path="/statics" exact component={Class} />
            <Route path="/dynamics" exact component={Class} />
            <Route path="/calculus" exact component={Class} />
            <Route path="/microelectronic_circuits" exact component={Class} />
            {/*<Route path="/suggest_a_book" exact component={SuggestABook} />*/}
            <Route path="/buy_calculator" exact component={BuyCalculator} />
            <Route path="/buy_book" exact component={BuyBook} />
            <Route path="/book/:bookId" exact component={Book} />
            <Route path="/book/:bookId/:chapterId" exact component={Book} />
            <Route path="/home" exact component={Home} />
            <Route path="/" exact component={Home} />
            <Route component={Home} />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default Layout