import React from "react";
import './BuyCalculator.scss'

const BuyCalculator = () => {
  return (
    <div>
      <h1>
        These are the 2 calculators that I recommend
      </h1>
      <div className="calculators">
        <div className="each-calculator">
          <h2>
            Non programmable: Ti-26x Pro
          </h2>
          <div>
            <div>
              <a href="https://amzn.to/3kt7zND" target="_blank" rel="noopener noreferrer">
                <img src="https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00PVV543G&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=jaghelp-20" alt="Ti-36x Pro"/>
              </a>
            </div>
            <div>
              <h3>
                <a href="https://amzn.to/3kt7zND" target="_blank" rel="noopener noreferrer">
                  Click here to buy
                </a>
              </h3>
              <div>
                I recommend this calculator for those of you who are not allowed a programmable calculator in your tests. This calculator can do pretty much everything standard and also solve matrices and systems of equations of 2 and 3 variables pretty quickly.
              </div>
            </div>
          </div>
        </div>
        <div className="each-calculator">
          <h2>
            Programmable: Ti-89
          </h2>
          <div>
            <div>
              <a href="https://amzn.to/2TqVQDE" target="_blank" rel="noopener noreferrer">
                <img src="https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B002C2UOSW&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=jaghelp-20" alt="Ti-89"/>
              </a>
            </div>
            <div>
              <h3>
                <a href="https://amzn.to/2TqVQDE" target="_blank" rel="noopener noreferrer">
                  Click here to buy
                </a>
              </h3>
              <div>
                For a fancy graphing calculator I have been using the Ti 89 for years. Is programmable and a bit on the expensive side, but mine has lasted me over 15 years.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyCalculator